<script>
import {
  CdrText,
  CdrGrid,
} from '@rei/cedar';

import FeaturedArticlesCard from './FeaturedArticlesCard.vue';

export default {
  name: 'FeatureArticles',
  components: {
    CdrText,
    CdrGrid,
    FeaturedArticlesCard,
  },
  props: {
    articles: { type: Array, default: () => [] },
  },
  data() {
    return {
      defaultImages: [
        { url: 'https://www.rei.com/dam/bilancieri_110818_0565-newsroom-default-storefront.jpg' },
        { url: 'https://www.rei.com/dam/harnois_102517_2142-newsroom-default-bridge.jpg' },
        { url: 'https://www.rei.com/dam/engel_111918_0008-newsroom-default-flagship.jpg' },
      ],
    };
  },
  methods: {
    checkForDefault(index) {
      // if there are more than 3 featured articles, a default image will always be available
      const indexRemainder = (index % this.defaultImages.length);
      return this.articles[index].heroImage
        ? null
        : this.defaultImages[indexRemainder];
    },
  },
};
</script>
<template>
  <section class="featured-articles">
    <cdr-text
      class="featured-articles__heading"
      tag="h2"
    >
      Top Stories
    </cdr-text>
    <cdr-grid class="featured-articles__grid">
      <div
        v-for="(article, index) in articles"
        :key="`featured-article-${article.entryId}`"
        class="featured-articles__grid--item"
      >
        <featured-articles-card
          :card="article"
          :lead-card="index === 0"
          :default-image="checkForDefault(index)"
        />
      </div>
    </cdr-grid>
  </section>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.featured-articles {
  width: 100%;
  &__heading {
    @include cdr-text-heading-serif-700;
    margin-bottom: $cdr-space-one-and-a-half-x;
  }
  &__grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    @include cdr-sm-mq-up {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &--item:hover {
      -webkit-transform: translateY(-.2rem);
      transform: translateY(-.2rem);
      transition: transform $cdr-duration-1-x $cdr-timing-function-ease;
    }
    &--item:first-child {
      grid-row: span 2;
    }
  }
}
</style>
