<script>
import {
  CdrText,
  CdrGrid,
  CdrLink,
} from '@rei/cedar';

import RecentArticlesCard from './RecentArticlesCard.vue';

export default {
  name: 'RecentArticles',
  components: {
    CdrText,
    CdrGrid,
    CdrLink,
    RecentArticlesCard,
  },
  props: {
    articles: { type: Array, default: () => [] },
  },
  data() {
    return {
      defaultImages: [
        {
          url: 'https://www.rei.com/dam/vagnini_181015_0549-newsroom-default-tent.jpg',
          altText: 'A woman sitting in a tent, looking out at the sunrise.',
          height: 683,
          width: 910,
        },
        {
          url: 'https://www.rei.com/dam/engel_073015_0264-newsroom-default-tree.jpg',
          altText: 'Two people planting a sapling.',
          height: 683,
          width: 910,
        },
        {
          url: 'https://www.rei.com/dam/shea_061219_0910-newsroom-default-kayaks.jpg',
          altText: 'A group of kayakers paddle down a river.',
          height: 683,
          width: 910,
        },
      ],
    };
  },
  methods: {
    endCard(index) {
      return index === this.articles.length - 1;
    },
    checkForDefault(index) {
      return this.defaultImages[index];
    },
  },
};
</script>
<template>
  <section class="recent-articles">
    <cdr-grid
      class="recent-articles__grid--layout"
      gutter="medium"
    >
      <cdr-text
        tag="h2"
        class="recent-articles__lead--heading"
      >
        Recent News
      </cdr-text>
      <cdr-link
        href="/newsroom/all"
        modifier="standalone"
        class="recent-articles__lead--link"
      >
        More recent news
      </cdr-link>
      <cdr-grid class="recent-articles__grid--cards">
        <div
          v-for="(article, index) in articles"
          :key="`article-${article.entryId}`"
          class="featured-articles__grid--item"
        >
          <recent-articles-card
            :card="article"
            :end-card="endCard(index)"
            :default-image="checkForDefault(index)"
          />
        </div>
      </cdr-grid>
    </cdr-grid>
  </section>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.recent-articles {
  width: 100%;
  margin-top: $cdr-space-two-x;
  &__grid {
    &--layout {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "title . link"
        "cards cards cards";
        @include cdr-xs-mq-only {
          grid-template-columns: 1fr;
          grid-template-rows: 3;
          grid-template-areas:
            "title"
            "cards"
            "link";
        }
    }
    &--cards {
      grid-area: cards;
      grid-template-columns: repeat(3, 1fr);
      @include cdr-md-mq-down {
        grid-template-columns: 1fr;
      }
      &--item:hover {
        -webkit-transform: translateY(-.2rem);
        transform: translateY(-.2rem);
        transition: transform $cdr-duration-1-x $cdr-timing-function-ease;
      }
    }
  }
  &__lead {
     margin: $cdr-space-one-and-a-half-x 0;
    &--heading {
      @include cdr-text-heading-serif-700;
      grid-area: title;
    }
    &--link {
      grid-area: link;
      align-self: center;
      justify-self: end;
      @include cdr-sm-mq-up {
        text-align: right;
      }
    }
  }
  }
</style>
