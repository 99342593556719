<script>
import {
  CdrText,
} from '@rei/cedar';

export default {
  name: 'FeaturedArticlesCard',
  components: {
    CdrText,
  },
  props: {
    card: { type: Object, default: () => {} },
    leadCard: { type: Boolean, default: false },
    defaultImage: { type: Object, default: () => {} },
  },
  computed: {
    renderCard() {
      return this.card?.articleTitle && this.card?.uri;
    },
    cardImage() {
      const renditions = this.card?.heroImage?.renditions;
      return renditions && renditions[renditions.length - 1];
    },
    cardStyles() {
      return {
        backgroundImage: this.defaultImage ? `url(${this.defaultImage?.url})` : `url(${this.cardImage?.url})`,
        height: this.leadCard ? '500px' : '235px',
      };
    },
  },
};
</script>
<template>
  <a
    v-if="renderCard"
    :href="card.uri"
    :aria-label="card.articleTitle"
    :data-analytics-id="card.articleTitle"
    class="featured-articles-card__link"
  >
    <article
      :style="cardStyles"
      data-ui="article-card"
      class="featured-articles-card__wrapper"
    >
      <div class="featured-articles-card__wrapper--content">
        <cdr-text
          tag="h3"
          class="featured-articles-card__heading"
        >
          {{ card.articleTitle }}
        </cdr-text>
        <cdr-text
          v-if="leadCard"
          class="featured-articles-card__text"
        >
          {{ card.articleAbstract }}
        </cdr-text>
      </div>
    </article>
  </a>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.featured-articles-card {
  &__link:hover {
    & .featured-articles-card__heading {
      text-decoration: underline;
    }
  }
  &__heading {
    @include cdr-text-heading-serif-500;
    color: $cdr-color-text-inverse;
  }
  &__text {
    @include cdr-text-body-300;
    margin-top: $cdr-space-half-x;
    color: $cdr-color-text-inverse;
  }
  &__wrapper {
    background-color: $cdr-color-background-primary;
    border-radius: $cdr-radius-soft;
    box-shadow: $cdr-prominence-raised;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    &--content {
      padding: $cdr-space-inset-one-x;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-decoration: none;
      text-shadow: 1px 2px 5px rgba(0,0,0,.5);
      background: linear-gradient(rgba(0,0,0,0) 0%, rgba(34,34,34,0.4) 10%, rgba(34,34,34,1) 100%);
    }
  }
}
</style>
