<script>
import {
  CdrText,
  CdrLink,
  CdrModal,
  CdrImg,
  IconDownload,

} from '@rei/cedar';

export default {
  name: 'ImageModal',
  components: {
    CdrText,
    CdrLink,
    CdrModal,
    CdrImg,
    IconDownload,
  },
  props: {
    modalContent: { type: Object, default: null },
    opened: { type: Boolean, default: false },
  },
  methods: {
    closed() {
      this.$emit('closed');
    },
  },
};
</script>
<template>
  <cdr-modal
    :opened="opened"
    label="Enlarge Image"
    aria-described-by="Photo details"
    @closed="closed"
  >
    <template #title>
      <cdr-text
        v-if="modalContent.title"
        tag="h3"
        class="image-modal__title"
      >
        {{ modalContent.title }}
      </cdr-text>
    </template>
    <template #default>
      <cdr-img
        :src="modalContent.imageViewUrl"
        :alt="modalContent.thumbImageAltText"
        class="image-modal__img cdr-pb-space-half-x"
        cover
        ratio="4-3"
      />
      <cdr-text
        v-if="modalContent.caption"
        class="image-modal__caption"
      >
        <em>{{ modalContent.caption }}</em>
      </cdr-text>
      <cdr-text
        v-if="modalContent.description"
        modifier="utility-sans-300"
        class="image-modal__desc"
      >
        {{ modalContent.description }}
      </cdr-text>
      <cdr-link
        v-if="modalContent.imageDownloadUrl"
        :href="modalContent.imageDownloadUrl"
        class="image-modal__link cdr-pt-space-quarter-x"
        modifier="standalone"
        download
      >
        Download high-res
        <IconDownload
          inherit-color
          class="image-modal__link--icon cdr-ml-space-quarter-x"
        />
      </cdr-link>
    </template>
  </cdr-modal>
</template>
<style lang="scss">
@import url('@rei/cedar/dist/style/cdr-modal.css');
@import url('@rei/cedar/dist/style/cdr-icon.css');
@import url('@rei/cedar/dist/style/cdr-link.css');
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.image-modal {
  &__title {
    @include cdr-text-heading-serif-500;
  }
  &__img {
    margin-bottom: $cdr-space-half-x;
  }
  &__caption {
    @include cdr-text-utility-sans-300;
    color: $cdr-color-text-secondary;
  }
  &__desc {
    @extend %cdr-text-utility-sans-300;
    color: $cdr-color-text-secondary;
    margin-top: $cdr-space-one-x;
  }
  &__link {
    margin-top: $cdr-space-quarter-x;
    &--icon {
      margin-left: $cdr-space-quarter-x;
    }
  }
}
</style>
