import '@rei/cedar/dist/cdr-fonts.css';
import '@rei/cedar/dist/cdr-reset.css';
import '@rei/cedar/dist/style/cdr-text.css';
import '@rei/cedar/dist/style/cdr-icon.css';
import '@rei/cedar/dist/style/cdr-grid.css';
import '@rei/cedar/dist/style/cdr-card.css';
import '@rei/cedar/dist/style/cdr-modal.css';
import '@rei/cedar/dist/style/cdr-button.css';
import '@rei/cedar/dist/style/cdr-img.css';
import '@rei/cedar/dist/style/cdr-link.css';

import createApp from './main';
import getClientPageData from '../../common/utils/clientPageData';

const { pageData: props } = getClientPageData();

const { app } = createApp(props);
app.mount('#app');
